import axios from '@/plugins/axios'

const createRespond = (variables) => {
  const endpoint = `${process.env.VUE_APP_AUTH_SERVER_URL}/page/respond`
  return axios.post(endpoint, variables)
}

export default {
  createRespond,
}
