import RespondService from '@/api/RespondService'

export default {
  namespaced: true,
  state: {
    loading: false,
    errors: null,
  },
  getters: {
    loading: ({ loading }) => loading,
    errors: ({ errors }) => errors,
  },
  mutations: {
    createRespondStart(state) {
      state.loading = true
      state.errors = null
    },
    createRespondSuccess(state) {
      state.loading = false
      state.errors = null
    },
    createRespondFailure(state, error) {
      state.loading = false
      state.errors = error
    },
  },
  actions: {
    async createRespond({ commit, rootGetters }, variables) {
      try {
        const scanId = rootGetters['qrCode/scanId']
        if (!scanId) throw Error('invalid qr page')
        commit('createRespondStart')
        await RespondService.createRespond({
          type: variables.type,
          content: variables.content,
          scanId,
        })
        commit('createRespondSuccess')
      } catch (e) {
        const message = e.response?.data?.message || 'not found'
        commit('createRespondFailure', message)
      }
    },
  },
}
