import Vue from 'vue'
import Vuex from 'vuex'
import qrCode from '@/store/modules/qrCode'
import respond from '@/store/modules/respond'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    qrCode,
    respond,
  },
})
