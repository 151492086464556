import QrCodeService from '@/api/qrCodeService'
export default {
  namespaced: true,
  state: {
    qrCode: null,
    qrCodeError: null,
    qrCodeLoading: false,
    scanId: null,
    isFreePlan: true,
    advertising: null,
  },
  getters: {
    scanId: ({ scanId }) => scanId,
    qrCode: ({ qrCode }) => qrCode,
    qrCodeError: ({ qrCodeError }) => qrCodeError,
    qrCodeLoading: ({ qrCodeLoading }) => qrCodeLoading,
    isFreePlan: ({ isFreePlan }) => isFreePlan,
    advertising: ({ advertising }) => advertising,
  },
  mutations: {
    getQrCodeStart(state) {
      state.qrCode = null
      state.scanId = null
      state.qrCodeLoading = true
      state.qrCodeError = null
    },
    getQrCodeSuccess(state, { qrCode, scanId, advertising, isFreePlan }) {
      state.qrCode = qrCode
      state.scanId = scanId
      state.advertising = advertising
      state.isFreePlan = isFreePlan
      state.qrCodeLoading = false
      state.qrCodeError = null
    },
    getQrCodeFailure(state, error) {
      state.qrCode = null
      state.scanId = null
      state.qrCodeLoading = false
      state.qrCodeError = error
    },
  },
  actions: {
    async getQrCode({ commit }, { key } = {}) {
      try {
        commit('getQrCodeStart')
        if (!key) throw Error('not found')
        const { data } = await QrCodeService.getQrCode(key)
        if (!data?.qrCode) throw Error('not found')
        commit('getQrCodeSuccess', data)
      } catch (e) {
        const message = e.response?.data?.message || 'not found'
        commit('getQrCodeFailure', message)
      }
    },
  },
}
